<template>
	<div class="row mb-2">
		<div
			v-if="!loadingOverride"
			class="col">
			<div class="h5">
				<span v-if="walletSplit">{{ translate('cashondelivery') }} + </span> {{ translate(paymentMethod.name) }}
			</div>
			<template v-if="billing.country_code !== '' && !loadingState">
				<b-row>
					<b-col>
						<p class="mb-0">
							<b>{{ billing.first_name }} {{ billing.last_name }}</b>
						</p>
						<p class="mb-0">
							{{ billing.address }}
						</p>
						<p class="mb-0">
							{{ billing.address2 }}
						</p>
						<p class="mb-0">
							{{ billing.address3 }}
						</p>
						<p class="mb-0">
							{{ billing.address4 }}
						</p>
						<p class="mb-0">
							{{ billing.city }}, {{ billing.region_name }}, {{ billing.postcode }}
						</p>
						<p class="mb-0">
							{{ translate(billing.country_code.toLowerCase()) }}
						</p>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-col
						:order="Number(walletSplit)"
						:class="{
							'col-auto': !walletSplit && !isSplitPayment && !['xs', 'sm'].includes(windowWidth),
							'col-12': ['xs', 'sm'].includes(windowWidth),
							'mt-2': ['xs', 'sm'].includes(windowWidth)
						}">
						<p class="mb-0">
							<b v-if="walletSplit">{{ translate(paymentMethod.name) }}</b>
							<b v-if="isSplitPayment">{{ translate('main_credit_card') }}</b>
						</p>
						<p
							v-if="isCreditCard"
							class="mb-0 d-inline">
							<img
								:src="require(`@/assets/images/common/cc/logos/${cardLogo}.png`)"
								class="my-auto mr-1"
								alt="Card Logo"
								style="width:45px; height:30px;">
							<span class="text-muted"> ****{{ paymentMethod.last_digits.length > 0 ? paymentMethod.last_digits : '' }}</span>
						</p>
						<p
							:class="{ 'ml-2': isCreditCard }"
							class="mb-0 d-inline">
							<b v-if="walletSplit">{{ cartTotal - walletSplitAmount | currency('usd', translate) }}</b>
							<b v-if="isSplitPayment">{{ displayedSplitAmount1 }}</b>
						</p>
					</b-col>
					<template>
						<b-col
							v-if="!isSplitPayment && canSplitWithWallet"
							:order="Number(!walletSplit)"
							:style="!walletSplit && !isCreditCard ? 'margin-top: -35px' : ''">
							<div
								:class="{ 'justify-content-end': !walletSplit, 'justify-content-start': walletSplit }"
								class="d-flex align-items-center h-100">
								<span
									v-if="!walletSplit"
									class="text-primary btn-link pointer"
									@click="$emit('walletSplit')">
									{{ translate('split_between_two_payment_methods', { first: translate('cashondelivery'), second: translate('other_method') }) }}
								</span>
								<b v-else>
									{{ translate('cashondelivery') }}
									<br>
									{{ walletSplitAmount | currency('usd', translate) }}
								</b>
							</div>
						</b-col>
						<b-col
							v-if="isSplitPayment"
							:class="{
								'col-md-6': !['xs', 'sm'].includes(windowWidth),
								'mt-2': ['xs', 'sm'].includes(windowWidth)
							}"
							class="col-12 mb-2">
							<p class="mb-0">
								<b>{{ translate('additional_credit_card') }}</b>
							</p>
							<p class="mb-0 d-inline">
								<img
									:src="require(`@/assets/images/common/cc/logos/${cardLogo2}.png`)"
									class="my-auto mr-1"
									alt="Card Logo"
									style="width:45px; height:30px;">
								<span class="text-muted"> ****{{ paymentMethod.last_digits_2.length > 0 ? paymentMethod.last_digits_2 : '' }}</span>
							</p>
							<p
								class="mb-0 d-inline ml-2">
								<b>{{ displayedSplitAmount2 }}</b>
							</p>
						</b-col>
					</template>
				</b-row>
			</template>
			<div
				v-else
				class="row mb-2">
				<div
					class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
					<div class="col-12">
						<div class="h2">
							<i class="fa fa-fw fa-spinner fa-pulse" />
							<h4 class="mt-3">
								{{ translate('loading') }}
							</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { AVAILABLECC, CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';
import { CC_PAYMENT_METHOD_BY_COUNTRY } from '@/settings/Purchase';
import Country from '@/util/Country';
import CreditCard from '@/util/CreditCard';
import Addresses from '../../mixins/Addresses';
import CommonMix from '../../mixins/Common';
import store from '../../store';
import { currency } from '@/config/Filters';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'PaymentOverview',
	filters: {
		currency,
	},
	mixins: [Addresses, CommonMix, WindowSizes],
	props: {
		loadingOverride: {
			type: Boolean,
			default: false,
		},
		cartTotal: {
			type: Number,
			default: 0,
		},
		canSplitWithWallet: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			creditCard: new CreditCard(),
			stateData: new Country(),
			billing: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				address3: '',
				address4: '',
				postcode: '',
				city: '',
				region_name: '',
				country_code: '',
			},
			paymentMethod: {
				name: '',
				card_type: '',
				last_digits: '',
				card_type_2: '',
				last_digits_2: '',
				split_amount: '',
				wallet_split: false,
			},
		};
	},
	computed: {
		walletSplit() {
			return !!this.paymentMethod.wallet_split;
		},
		walletSplitAmount() {
			return Number(this.paymentMethod.wallet_split_amount);
		},
		loadingState() {
			return this.stateData.data.loading;
		},
		cardLogo() {
			return this.getCardLogo(this.paymentMethod.card_type);
		},
		cardLogo2() {
			return this.getCardLogo(this.paymentMethod.card_type_2);
		},
		creditCardPaymentMethod() {
			return CC_PAYMENT_METHOD_BY_COUNTRY[this.country] ?? CC_PAYMENT_METHOD_BY_COUNTRY.default;
		},
		isCreditCard() {
			return CREDIT_CARD_FORM_METHODS.includes(this.paymentMethod.name);
		},
		isSplitPayment() {
			return !!this.paymentMethod.last_digits_2;
		},
		splitAmount1() {
			return this.paymentMethod.split_amount;
		},
		splitAmount2() {
			return this.cartTotal - this.splitAmount1;
		},
		displayedSplitAmount1() {
			return this.formatAmount(this.splitAmount1);
		},
		displayedSplitAmount2() {
			return this.formatAmount(this.splitAmount2);
		},
	},
	watch: {
		initialInformation() {
			if (!store.getters.getCompletedSteps().includes('PurchasePayment')) {
				this.loadFromInitialInformation();
			}
		},
		addressLoading(newVal) {
			if (!newVal && this.hasAddressData && store.getters.getCompletedSteps().includes('PurchasePayment')) {
				if (this.hasAddressData) {
					this.loadFromStoredPaymentStep(newVal);
				} else {
					this.$emit('noData');
				}
			}
		},
	},
	mounted() {
		if (store.getters.getCompletedSteps().includes('PurchasePayment')) {
			this.loadFromStoredPaymentStep();
		} else {
			this.loadFromInitialInformation();
		}
	},
	methods: {
		loadFromInitialInformation() {
			if (Object.keys(this.initialInformation).length) {
				this.useInitialInformation();
			}
			return null;
		},
		loadFromStoredPaymentStep() {
			if (this.hasAddressData) {
				this.useStepInformation();
			}
			return null;
		},
		useStepInformation() {
			const stepInfo = this.getStepInformation('PurchasePayment');
			const { billing: billingAddress, payment_method: paymentMethod } = stepInfo.payment;

			Object.keys(paymentMethod).forEach((element) => {
				this.paymentMethod[element] = paymentMethod[element];
			});
			this.paymentMethod.name = paymentMethod.name;

			if (this.paymentMethod.card_id) {
				this.creditCard.getCreditCard(this.$user.details().id, this.paymentMethod.card_id).then((response) => {
					this.paymentMethod = {
						...this.paymentMethod,
						card_type: response.attributes.card_type,
						last_digits: response.attributes.last_digits,
					};
				}).catch(() => {});
			}

			if (this.paymentMethod.card_id_2) {
				this.creditCard.getCreditCard(this.$user.details().id, this.paymentMethod.card_id_2).then((response) => {
					this.paymentMethod = {
						...this.paymentMethod,
						card_type_2: response.attributes.card_type,
						last_digits_2: response.attributes.last_digits,
					};
				}).catch(() => {});
			}

			this.paymentMethod.split_amount = paymentMethod.split_amount ?? null;

			if (billingAddress.address_id) {
				this.billing.address_id = billingAddress.address_id;
				const foundAddress = this.addressData.find((address) => +address.id === +this.billing.address_id);

				if (!foundAddress) {
					this.$emit('noData');
					return null;
				}

				this.billing = {
					...this.billing,
					first_name: foundAddress.attributes.first_name,
					last_name: foundAddress.attributes.last_name,
					address: foundAddress.attributes.address,
					address2: foundAddress.attributes.address2,
					address3: foundAddress.attributes.address3,
					address4: foundAddress.attributes.address4,
					postcode: foundAddress.attributes.postcode,
					city: foundAddress.attributes.city,
					country_code: foundAddress.attributes.country.code.toLowerCase(),
				};

				this.stateData.getState(foundAddress.attributes.region.id).then((state) => {
					this.billing.region_name = state.attributes.name;
				});
			} else {
				this.useCreditCardAddress(billingAddress);
			}
			return null;
		},
		useInitialInformation() {
			if (Array.isArray(this.initialInformation.credit_card.billing_address) || !this.initialInformation.credit_card.card) {
				this.$emit('noData');
			} else {
				const cardData = this.initialInformation.credit_card.card;
				this.paymentMethod = {
					name: this.creditCardPaymentMethod,
					card_type: cardData.card_type,
					last_digits: cardData.last_digits,
				};

				const billingAddress = this.initialInformation.credit_card.billing_address;
				this.useCreditCardAddress(billingAddress);
				this.$emit('changePaymentMethodSelected', this.paymentMethod.name);
			}
		},
		useCreditCardAddress(address) {
			this.billing = {
				...this.billing,
				first_name: address.firstname,
				last_name: address.lastname,
				address: address.street[0],
				address2: address.street[1],
				postcode: address.postcode,
				city: address.city,
				country_code: address.country_id,
			};

			this.stateData.getState(address.region.region_id).then((state) => {
				this.billing.region_name = state.attributes.name;
			});
		},
		getCardLogo(cardType) {
			if (cardType.length > 0 && AVAILABLECC.includes(cardType)) {
				return cardType;
			}
			return 'cc';
		},
		formatAmount(amount) {
			return `$${amount.toFixed(2)} USD`;
		},
	},
};
</script>
