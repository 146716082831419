<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			v-show="paymentMethodEnabled"
			v-if="canSplitPayment"
			class="row">
			<div class="col-12">
				<switch-toggle
					id="split_payment"
					v-model="splitPayment"
					name="split_payment"
					variant="success"
					class="mr-2"
					pill />
				<label for="split_payment">
					{{ translate('split_payment') }}
				</label>
				<hr class="p-1 m-1">
			</div>
		</div>
		<b-row v-if="showTitle">
			<b-col>
				<div class="h4 my-2">
					{{ translate('cc_information') }}
				</div>
			</b-col>
		</b-row>
		<div v-show="!splitPayment">
			<b-row class="px-3">
				<div
					:class="{'px-0': !['xs'].includes(windowWidth) && (!addCreditCard && creditCardsData.length) }"
					class="col-12 address-list">
					<credit-card-grid
						v-if="!addCreditCard && paymentMethodEnabled"
						:loading="creditCardsLoading"
						:data="creditCardsData"
						:show-select="true"
						:show-default="false"
						:show-disclaimer="false"
						:selected="computedCardId"
						@change="$emit('update:cardId', $event)" />
					<div
						v-if="!addCreditCard && creditCardsData.length && !creditCardsLoading && paymentMethodEnabled"
						class="row">
						<div class="col-12">
							<hr class="mt-0">
						</div>
					</div>
					<template v-if="(addCreditCard || !creditCardsData.length) && !creditCardsLoading && paymentMethodEnabled">
						<div class="row mb-2">
							<div
								:class="{'px-0': !['xs'].includes(windowWidth) }"
								class="col-12">
								<template v-for="(item, index) in displayedAllowedCC">
									<img
										:key="index"
										:src="require(`@/assets/images/common/cc/logos/${item}.png`)"
										alt="Credit Card"
										align="right"
										class="text-right ml-2 resize-img">
								</template>
							</div>
						</div>
						<new-credit-card
							ref="newCreditCardView"
							:borderless="true"
							:show-buttons="false"
							:include-title="false"
							:show-disclaimer="true"
							:show-default-switch="false"
							new-address-btn-variant="link"
							new-address-btn-class="pl-2 py-0"
							no-body
							@cardCreated="cardCreated">
							<template
								v-if="addCreditCard"
								v-slot:middle>
								<button
									:class="{ 'w-100' : ['xs', 'sm'].includes(windowWidth) }"
									class="btn btn-link b-4 float-left px-2 pt-0 pb-4"
									@click="cardFormState">
									{{ translate('see_credit_card_list') }}
								</button>
							</template>
						</new-credit-card>
					</template>
					<b-alert
						:show="!paymentMethodEnabled"
						variant="danger"
						v-html="translate('creditcard_unavailable', {
							url: $router.resolve({ name: 'Store' }).href,
							max: typeof paymentMethodConditions.max !== 'undefined' ? paymentMethodConditions.max : '',
							min: typeof paymentMethodConditions.min !== 'undefined' ? paymentMethodConditions.min : '',
						})" />
				</div>
			</b-row>
		</div>
		<b-row>
			<div
				v-if="!addCreditCard && !creditCardsLoading && !splitPayment"
				class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
				<button
					:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
					class="btn btn-link b-4 float-left p-0"
					@click="cardFormState">
					{{ translate('use_another_credit_card') }}
				</button>
			</div>
		</b-row>
		<div v-show="splitPayment">
			<b-row
				v-if="!creditCardsData.length || addCreditCard1 || addCreditCard2"
				class="px-2 m-2 justify-content-end">
				<div class="row mb-2">
					<div
						:class="{'px-0': !['xs'].includes(windowWidth) }"
						class="col-12">
						<template v-for="(item, index) in displayedAllowedCC">
							<img
								:key="index"
								:src="require(`@/assets/images/common/cc/logos/${item}.png`)"
								alt="Credit Card"
								align="right"
								class="text-right ml-2 resize-img">
						</template>
					</div>
				</div>
			</b-row>
			<split-payment-credit-card-form-v2
				ref="splitPaymentCreditCardForm"
				:split-payment="splitPayment"
				:split-amount.sync="splitPaymentAmount"
				:cart-total="cartTotal"
				:amount-errors="amountErrors"
				:credit-card1-errors="lacoreV2CreditCardErrors"
				:credit-card2-errors="lacoreV2CreditCard2Errors"
				:payment-method-enabled="paymentMethodEnabled"
				:credit-cards-loading="creditCardsLoading"
				:credit-cards-data="creditCardsData"
				:card-id1="cardId1"
				:card-id2="cardId2"
				:add-credit-card1="addCreditCard1"
				:add-credit-card2="addCreditCard2"
				@update:cardId1="$emit('update:cardId1', $event)"
				@update:cardId2="$emit('update:cardId2', $event)"
				@update:addCreditCard1="addCreditCard1 = !addCreditCard1"
				@update:addCreditCard2="addCreditCard2 = !addCreditCard2"
				@cardCreated="cardCreated"
				@getCreditCardsData="getCreditCardsData"
				@setupStart="$emit('preparing', true)"
				@setupEnd="$emit('preparing', false); splitCreditCardPaymentCallbacks = $event" />
		</div>
		<div
			v-if="splitPayment && !addCreditCard1 && !addCreditCard2"
			class="row no-gutters mb-3 mt-3">
			<div class="col">
				<b-alert
					show
					class="mb-0"
					variant="warning">
					<div class="custom-control custom-checkbox my-auto">
						<input
							id="discount"
							v-model="splitPaymentAgreement"
							type="checkbox"
							class="custom-control-input">
						<label
							for="discount"
							class="custom-control-label">
							{{ translate('split_payment_agreement') }}
						</label>
					</div>
				</b-alert>
			</div>
		</div>
		<b-row
			v-if="paymentMethodEnabled && ((addCreditCard && !splitPayment) || ((addCreditCard1 || addCreditCard2) && splitPayment))"
			no-gutters
			class="justify-content-end">
			<b-col
				:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
				md="auto">
				<b-button
					style="min-width: 150px"
					class="w-100"
					variant="light"
					size="lg"
					@click="cardFormState">
					{{ translate('cancel') }}
				</b-button>
			</b-col>
			<b-col
				:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
				md="auto">
				<b-button
					style="min-width: 200px"
					class="w-100"
					variant="primary"
					size="lg"
					@click="newCreditCard()">
					{{ translate('save_credit_card_button') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import SwitchToggle from '@/components/Switch/index.vue';
import { PaymentMethods, Profile, PurchasePayment } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import CreditCard from '@/util/CreditCard';
import CreditCardGrid from '@/views/Profile/CreditCard/components/CreditCardGrid.vue';
import NewCreditCard from '@/views/Profile/CreditCard/components/NewCreditCard.vue';
import { ALLOWEDCC_BY_COUNTRY } from '@/settings/CreditCard';
import CreditCardFormV2 from '@/mixins/CreditCardFormV2';
import SplitPaymentCreditCardFormV2 from '@/components/SplitPaymentCreditCardFormV2/index.vue';

export default {
	name: 'CreditCardPayment',
	messages: [PaymentMethods, PurchasePayment, Profile],
	components: {
		CreditCardGrid,
		NewCreditCard,
		SwitchToggle,
		SplitPaymentCreditCardFormV2,
	},
	mixins: [CreditCardFormV2, WindowSizes],
	props: {
		showTitle: {
			type: Boolean,
			default: false,
		},
		paymentMethodConditions: {
			type: Object,
			default: () => ({}),
		},
		cardId: {
			type: [String, Number],
			default: 0,
		},
		cardId1: {
			type: [String, Number],
			default: 0,
		},
		cardId2: {
			type: [String, Number],
			default: 0,
		},
		canSplitPayment: {
			type: Boolean,
			default: false,
		},
		cartTotal: {
			type: Number,
			default: 0,
		},
		isSplitPayment: {
			type: Boolean,
			default: false,
		},
		splitPaymentInitialAmount: {
			type: Number,
			default: 0,
		},
		validationErrors: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			creditCards: new CreditCard(),
			addCreditCard: false,
			addCreditCard1: false,
			addCreditCard2: false,
		};
	},
	computed: {
		hasCreditCardsData() {
			const response = this.creditCardsData.length;
			return !!response;
		},
		creditCardsData() {
			try {
				const { data } = this.creditCards.data.response.data;
				if (data === undefined) {
					return [];
				}
				return data;
			} catch (error) {
				return [];
			}
		},
		creditCardsLoading() {
			return !!this.creditCards.data.loading;
		},
		displayedAllowedCC() {
			return ALLOWEDCC_BY_COUNTRY[this.country] ?? ALLOWEDCC_BY_COUNTRY.default;
		},
		paymentMethodEnabled() {
			return this.paymentMethodConditions.isAvailable;
		},
		computedCardId() {
			return Number(this.cardId);
		},
	},
	watch: {
		addCreditCard(addCreditCard) {
			this.$emit('addCreditCard', addCreditCard);
		},
		addCreditCard1(addCreditCard) {
			this.$emit('addCreditCard1', addCreditCard);
		},
		addCreditCard2(addCreditCard) {
			this.$emit('addCreditCard2', addCreditCard);
		},
		creditCardsLoading(loading) {
			this.$emit('loading', loading);
			if (!loading) {
				if (!this.hasCreditCardsData) {
					this.addCreditCard1 = true;
					this.addCreditCard2 = true;
				}
			}
		},
		splitPaymentAgreement(agreement) {
			this.$emit('update:splitPaymentAgreement', agreement);
		},
		splitPayment(splitPayment) {
			this.$emit('update:splitPayment', splitPayment);
		},
		splitPaymentAmount(splitAmount) {
			this.$emit('update:splitAmount', splitAmount);
		},
		validationErrors(newVal) {
			this.amountErrors = newVal['payment.payment_method.split_amount'];
		},
	},
	mounted() {
		this.getCreditCardsData();
		this.splitPayment = this.isSplitPayment;
		this.splitPaymentAmount = this.splitPaymentInitialAmount;
	},
	methods: {
		getCreditCardsData(setCardId = true) {
			return this.creditCards.getCreditCards(this.$user.details().id).then((response) => {
				if (setCardId && response.length) {
					if (!response.some((card) => Number(card.id) === Number(this.cardId))) {
						this.$emit('update:cardId', Number(response[0].id));
					}
				} else {
					this.addCreditCard = true;
				}
			});
		},
		cardFormState() {
			this.addCreditCard = !this.addCreditCard;
		},
		async cardCreated(cardId) {
			this.$emit('update:cardId', cardId);
			this.getCreditCardsData(false)
				.then(() => this.$emit('cardCreated'))
				.finally(() => { this.addCreditCard = false; });
		},
		newCreditCard() {
			if (this.addCreditCard && !this.splitPayment) {
				this.$refs.newCreditCardView.newCreditCard();
			}

			if (this.splitPayment) {
				this.$refs.splitPaymentCreditCardForm.newCreditCards();
			}
		},
		updateSplitPaymentValue(newVal) {
			this.splitPayment = newVal;
		},
	},
};
</script>
