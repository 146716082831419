<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-row v-if="showTitle">
			<b-col>
				<div class="h4 my-2">
					{{ translate('gcash') }}
				</div>
			</b-col>
		</b-row>
		<b-row class="row mb-3">
			<b-col>
				<b-alert
					:show="!paymentMethodEnabled"
					variant="danger"
					v-html="translate('gcash_unavailable', {
						url: $router.resolve({ name: redirectTo }).href,
						min: typeof paymentMethodConditions.min !== 'undefined' ? paymentMethodConditions.min : '',
						max: typeof paymentMethodConditions.max !== 'undefined' ? paymentMethodConditions.max : '',
					})" />
				<div v-if="paymentMethodEnabled">
					<b-alert
						show
						variant="info"
						v-html="translate('gcash_details')" />
					<b-alert
						show
						variant="warning"
						v-html="translate('gcash_details_place_holder', { hours: limitTransferDays * 24 })" />
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { LIMIT_GCASH_TRANSFER_DAYS } from '@/settings/Purchase';
import { PaymentMethods, PurchasePayment } from '@/translations';

export default {
	name: 'GCashPayment',
	messages: [PaymentMethods, PurchasePayment],
	props: {
		showTitle: {
			type: Boolean,
			default: false,
		},
		paymentMethodConditions: {
			type: Object,
			default: () => ({}),
		},
		redirectTo: {
			type: String,
			default: 'Store',
		},
	},
	data() {
		return {
			limitTransferDays: LIMIT_GCASH_TRANSFER_DAYS,
		};
	},
	computed: {
		paymentMethodEnabled() {
			return this.paymentMethodConditions.isAvailable;
		},
	},
};
</script>
