import { PAYMENT_METHOD_LIMITS } from '@/settings/Purchase';

export default {
	data() {
		return {
			flowName: '',
			amountToPay: 0,
			skipMinValidation: false,
		};
	},
	computed: {
		paymentMethodConditions() {
			const paymentMethodLimits = { ...PAYMENT_METHOD_LIMITS.defaultCountry, ...PAYMENT_METHOD_LIMITS[this.country] };
			return Object.fromEntries(Object.entries({
				...paymentMethodLimits.defaultFlow,
				...paymentMethodLimits[this.flowName],
			})
				.map((paymentMethod) => {
					const [codeName, limits] = paymentMethod;
					const subtotalMin = typeof limits.min !== 'undefined' ? this.amountToPay >= limits.min : true;
					const subtotalMax = typeof limits.max !== 'undefined' ? this.amountToPay <= limits.max : true;
					const isSubtotalWithinLimits = (subtotalMin || this.skipMinValidation) && subtotalMax;
					return [
						codeName,
						{
							min: this.skipMinValidation ? 0 : limits.min,
							max: limits.max,
							isAvailable: isSubtotalWithinLimits,
						},
					];
				}));
		},
	},
	methods: {
		setFlowName(name) {
			this.flowName = name;
		},
		setAmountToPay(amount) {
			this.amountToPay = amount;
		},
	},
};
