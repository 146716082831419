export const MARKETING_PROVIDER = 'MailChimp';
export const TERMS_AND_CONDITIONS_LINK = 'https://velovita.s3-us-west-1.amazonaws.com/assets/terms.pdf?v=8';
export const TERMS_AND_CONDITIONS_REDIRECTION = '/member-business-agreements';
export const MEMBER_REWARDS_REDIRECTION = '/member-rewards';
export const TERMS_AND_CONDITIONS_LINK_EU = 'https://velovita.s3-us-west-1.amazonaws.com/assets/terms_eu.pdf?v=6';
export const CUSTOMER_SERVICE_EMAIL = 'support@velovita.com';
export const DIRECT_SALES_SUPPORT_EMAIL = 'supportDS@velovita.com';
export const DIRECT_SALES_SUPPORT_NUMBER = '(305) 982-7329';
export const DIRECT_SALES_SUPPORT_NUMBER_LINK = '+13059827329';
export const MEMBER_REWARDS_VIDEO_URL = 'https://player.vimeo.com/video/860354122';
export const TIMEZONE = 'ET';
export const TIMEZONE_CUSTOMER_SERVICE = 'ET';
export const TIMEZONE_NAME = 'America/New_York';
export const COMPANY_ADDRESS_LINE_1 = '1111 Park Center';
export const COMPANY_ADDRESS_LINE_2 = 'Blvd, Ste 450';
export const COMPANY_ADDRESS_LINE_3 = 'Miami, FL 33169';
export const COMPANY_FACEBOOK = '@VelovitaOfficial';
export const COMPANY_INSTAGRAM = '@velovita_official';
export const APP_SYMBOL = 'https://velovita.s3-us-west-1.amazonaws.com/backoffice/assets/themes/Velovita/symbol_light_gray.svg';

export const REFRESH_NOTIFICATIONS = 300000; // 300000ms = 5min

export default {};
