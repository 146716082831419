<template>
	<div>
		<b-row
			v-if="showTitle">
			<b-col>
				<div class="h4 my-2">
					{{ translate('wallet_information') }}
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<div
					v-if="!walletBalanceLoading && balance.hasOwnProperty(walletType)"
					class="h4 text-right mb-0">
					{{ translate('current_balance') }}: {{ balance[walletType] }}
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<input-text
					id="wallet_password"
					name="wallet_password"
					:required="true"
					:label="translate('wallet_password')"
					:errors="walletPasswordErrors"
					:setter-value="walletPassword"
					type="password"
					@dataChanged="walletPassword = $event" />
			</b-col>
		</b-row>
		<b-row
			v-if="(typeof walletTypeErrors !== 'undefined') && walletTypeErrors.length > 0">
			<b-col>
				<b-alert
					variant="danger"
					show>
					<div
						v-for="error in walletTypeErrors"
						:key="error">
						<span
							v-text="error" />
						<br>
					</div>
				</b-alert>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import InputText from '@/components/InputText';
import { PurchasePayment } from '@/translations';

export default {
	name: 'WalletPayment',
	messages: [PurchasePayment],
	components: { InputText },
	props: {
		showTitle: {
			type: Boolean,
			default: false,
		},
		walletBalanceLoading: {
			type: Boolean,
			default: false,
		},
		balance: {
			type: Object,
			default: () => ({}),
		},
		walletType: {
			type: String,
			required: true,
		},
		walletTypeErrors: {
			type: Array,
			default: () => [],
		},
		walletPasswordErrors: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			walletPassword: '',
		};
	},
	watch: {
		walletPassword(password) {
			this.$emit('passwordChanged', password);
		},
	},
};
</script>
