<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row">
			<div class="col-12">
				<b-alert
					show
					v-html="translate('split_payment_instructions')" />
			</div>
		</div>
		<ul class="list-group w-100">
			<!-- Payment #1 -->
			<li class="list-group-item h-100 p-0">
				<div class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
					<div class="col my-auto align-middle">
						<div class="h5 m-0">
							{{ translate('main_credit_card') }}
						</div>
					</div>
				</div>
				<!-- Amount Input -->
				<div
					id="amountInput"
					class="row m-2 align-items-center">
					<div class="col-6">
						<label
							for="amount"
							class="label">
							{{ translate('amount_to_pay_with_card') }}
							<span class="text-danger">*</span>
						</label>
					</div>
					<div class="col-6">
						<input
							id="amount"
							v-model="splitAmountValue1"
							name="amount"
							type="number"
							:class="hasAmountError ? 'is-invalid' : ''"
							class="form-control text-right"
							aria-describedby="amount"
							placeholder="$0.00">
					</div>
					<div class="col-12">
						<template v-if="hasAmountError">
							<p
								v-for="error in amountErrors"
								:key="error"
								class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
								v-text="error" />
						</template>
					</div>
				</div>
				<!-- Credit card form #1 -->
				<div class="row m-2">
					<div class="col">
						<credit-card-grid
							v-if="!addCreditCard1 && paymentMethodEnabled"
							:loading="creditCardsLoading"
							:data="creditCardsData"
							:show-select="true"
							:show-default="false"
							:show-disclaimer="false"
							:selected="computedCardId1"
							@change="handleCardChange(1, $event)" />
						<b-row>
							<div
								v-if="!addCreditCard1 && !creditCardsLoading && creditCardsData.length"
								class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-2">
								<button
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
									class="btn btn-link b-4 float-left p-0"
									@click="$emit('update:addCreditCard1')">
									{{ translate('use_another_credit_card') }}
								</button>
							</div>
						</b-row>
						<lacore-credit-card-form-v2
							v-if="addCreditCard1 || !creditCardsData.length"
							ref="newCreditCardView1"
							field-id-prefix="payment-1"
							:address-line1="form.address"
							:address-line2="form.address2"
							:city="form.city"
							:postcode="form.postcode"
							:region-id="form.region_id"
							:new-state-code="form.state_code"
							:country-code="form.country_code"
							:import-credit-card-errors="creditCard1Errors"
							@setupStart="preparing = true"
							@setupEnd="preparing = false; submitCallbacks[0] = $event"
							@submitSuccess="token = $event"
							@failed="$emit" />
						<div
							v-if="addCreditCard1 && creditCardsData.length">
							<button
								:class="{ 'w-100' : ['xs', 'sm'].includes(windowWidth) }"
								class="btn btn-link b-4 float-left px-1 pt-0 pb-4"
								@click="$emit('update:addCreditCard1')">
								{{ translate('see_credit_card_list') }}
							</button>
						</div>
					</div>
				</div>
			</li>
			<!-- Payment #2 -->
			<li class="list-group-item h-100 p-0">
				<div class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
					<div class="col my-auto align-middle">
						<div class="h5 m-0">
							{{ translate('additional_credit_card') }}
						</div>
					</div>
				</div>
				<!-- Amount -->
				<div
					id="amountInput"
					class="row mx-2 my-3 align-items-center">
					<div class="col-6">
						{{ translate('amount_to_pay_with_card') }}
					</div>
					<div class="col-6 text-right">
						<b>{{ splitAmountValue2 }}</b>
					</div>
				</div>
				<!-- Credit card form #2 -->
				<div class="row m-2">
					<div class="col">
						<credit-card-grid
							v-if="!addCreditCard2 && paymentMethodEnabled"
							:loading="creditCardsLoading"
							:data="creditCardsData"
							:show-select="true"
							:show-default="false"
							:show-disclaimer="false"
							:selected="computedCardId2"
							@change="handleCardChange(2, $event)" />
						<b-row>
							<div
								v-if="!addCreditCard2 && !creditCardsLoading && creditCardsData.length"
								class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pb-2">
								<button
									:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
									class="btn btn-link b-4 float-left p-0"
									@click="$emit('update:addCreditCard2')">
									{{ translate('use_another_credit_card') }}
								</button>
							</div>
						</b-row>
						<lacore-credit-card-form-v2
							v-if="addCreditCard2 || !creditCardsData.length"
							ref="newCreditCardView2"
							field-id-prefix="payment-2"
							:address-line1="form.address"
							:address-line2="form.address2"
							:city="form.city"
							:postcode="form.postcode"
							:region-id="form.region_id"
							:new-state-code="form.state_code"
							:country-code="form.country_code"
							:import-credit-card-errors="creditCard2Errors"
							@setupStart="preparing = true"
							@setupEnd="preparing = false; submitCallbacks[1] = $event" />
						<div
							v-if="addCreditCard2 && creditCardsData.length">
							<button
								:class="{ 'w-100' : ['xs', 'sm'].includes(windowWidth) }"
								class="btn btn-link b-4 float-left px-1 pt-0 pb-4"
								@click="$emit('update:addCreditCard2')">
								{{ translate('see_credit_card_list') }}
							</button>
						</div>
					</div>
				</div>
			</li>
		</ul>
		<li
			v-if="addCreditCard1 || addCreditCard2"
			class="list-group-item border-left-0 border-right-0 border-bottom-0 rounded-0 pb-0">
			<div class="row">
				<div class="col-12 mt-3 px-0">
					<div class="h4 mb-2">
						{{ translate('billing_address') }}
					</div>
				</div>
			</div>
			<div class="row pt-1">
				<div class="col-12">
					<b-alert show>
						{{ translate('billing_disclaimer') }}
					</b-alert>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<address-radio-button
						v-if="hasAddressData && !useAnotherAddress"
						:address-data="addressData"
						:address-loading="addressLoading"
						:form-import="form"
						:hide-new-address="addAddress"
						:display-add-new-address="false"
						@add-address="addressFormState" />
					<transition
						v-if="!hasAddressData || useAnotherAddress"
						name="component-fade"
						mode="in-out">
						<address-country-form
							:user-country="true"
							:form-import.sync="form"
							:errors-import="newAddressErrors"
							:country-code-import="country"
							:display-cancel-button="false"
							:display-submit-button="false"
							:display-goback-button="false" />
					</transition>
					<div
						class="mt-0 pt-0">
						<button
							v-if="!useAnotherAddress"
							:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
							class="btn btn-link b-4 float-left p-0"
							@click="useAnotherAddress = true">
							{{ translate('use_another_address') }}
						</button>
					</div>
				</div>
			</div>
		</li>
	</div>
</template>
<script>
import LacoreCreditCardFormV2 from '@/components/LacoreCreditCardFormV2';
import { Profile, Purchase, Addresses as addressesMessages } from '@/translations';
import CreditCardGrid from '@/views/Profile/CreditCard/components/CreditCardGrid.vue';
import WindowSizes from '@/mixins/WindowSizes';
import CommonMix from '../../views/Checkout/mixins/Common';
import AddressCountryForm from '@/components/AddressCountryForm';
import AddressBook from '@/util/AddressBook';
import AddressRadioButton from '@/components/AddressRadioButton';
import { SYSTEM_ALERT, UNPROCESSABLE } from '@/settings/Errors';
import CreditCard from '@/util/CreditCard';

export default {
	name: 'SplitPaymentCreditCardFormV2',
	messages: [Profile, Purchase, addressesMessages],
	components: {
		LacoreCreditCardFormV2, CreditCardGrid, AddressCountryForm, AddressRadioButton,
	},
	mixins: [WindowSizes, CommonMix],
	props: {
		splitAmount: {
			type: Number,
			default: 0,
		},
		cartTotal: {
			type: Number,
			required: true,
		},
		amountErrors: {
			type: Array,
			default: () => [],
		},
		creditCard1Errors: {
			type: Object,
			default: () => ({}),
		},
		creditCard2Errors: {
			type: Object,
			default: () => ({}),
		},
		paymentMethodEnabled: {
			type: Boolean,
			default: false,
		},
		splitPayment: {
			type: Boolean,
			default: false,
		},
		creditCardsLoading: {
			type: Boolean,
			default: false,
		},
		creditCardsData: {
			type: Array,
			default: () => ([]),
		},
		addCreditCard1: {
			type: Boolean,
			default: false,
		},
		addCreditCard2: {
			type: Boolean,
			default: false,
		},
		form: {
			type: Object,
			default: () => ({
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				address3: '',
				address4: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
			}),
		},
	},
	data() {
		return {
			submitCallbacks: [
				() => {},
				() => {},
			],
			preparing: false,
			splitAmountValue1: this.splitAmount,
			cardId1: null,
			cardId2: null,
			sameAs: false,
			useAnotherAddress: false,
			addressBook: new AddressBook(),
			newAddress: new AddressBook(),
			addAddress: false,
			alert: new this.$Alert(),
			creditCard1: new CreditCard(),
			creditCard2: new CreditCard(),
		};
	},
	computed: {
		splitAmountValue2() {
			return `$${(this.cartTotal - this.splitAmountValue1).toFixed(2)} USD`;
		},
		hasAmountError() {
			return !!this.amountErrors.length;
		},
		computedCardId1() {
			return Number(this.cardId1);
		},
		computedCardId2() {
			return Number(this.cardId2);
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		hasAddressData() {
			const response = this.addressData.length;
			return !!response;
		},
		newAddressErrors() {
			return this.newAddress.errors.errors;
		},
	},
	watch: {
		preparing(newVal) {
			if (newVal === true) {
				this.$emit('setupStart');
			} else if (newVal === false) {
				this.$emit('setupEnd', this.submitCallbacks);
			}
		},
		splitAmount(newVal) {
			this.splitAmountValue1 = newVal;
		},
		splitAmountValue1(newVal) {
			if (newVal < 0 || Number.isNaN(newVal)) {
				this.splitAmountValue1 = null;
			}
			if (newVal >= this.cartTotal) {
				this.splitAmountValue1 = this.cartTotal;
			}
			this.$emit('update:splitAmount', Number(newVal));
		},
		hasAddressData: {
			deep: true,
			handler(newVal) {
				this.addAddress = !newVal;
			},
		},
		splitPayment(newVal) {
			if (newVal) {
				this.getAddressList();
			} else {
				this.form.address_id = null;
			}
		},
	},
	methods: {
		async getAddressList(useLast = false) {
			if (this.$user.auth()) {
				await this.addressBook.getAddresses(this.$user.details().id).then((response) => {
					if (response.length) {
						if (useLast) {
							this.form.address_id = response[response.length - 1].id;
						} else {
							this.form.address_id = response[0].id;
						}
					}
				});
			}
		},
		handleCardChange(gridNumber, selectedCard) {
			if (gridNumber === 1) {
				this.cardId1 = selectedCard;
				this.$emit('update:cardId1', selectedCard);
			} else if (gridNumber === 2) {
				this.cardId2 = selectedCard;
				this.$emit('update:cardId2', selectedCard);
			}
		},
		addressFormState() {
			if (Object.keys(this.errors.errors).length > 0) {
				const errorFields = Object.keys(this.errors.errors);
				errorFields.forEach((field) => {
					delete this.errors.errors[field];
					this.errors.errors = { ...this.errors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
		async newCreditCards() {
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);
			try {
				if (this.useAnotherAddress) {
					await this.getAddress();
				} else {
					// eslint-disable-next-line eqeqeq
					const address = await this.addressData.find((element) => element.id == this.form.address_id).attributes;
					this.form = {
						...this.form, address: address.address, address2: address.address2, city: address.city, postcode: address.postcode, region_id: address.region.id.toString(), new_state_code: '', country_code: address.country.code,
					};
				}
				this.$nextTick(async () => {
					if (this.addCreditCard1) {
						const cardInfo1 = await this.$refs.newCreditCardView1.handleResponse();
						const payload = { token: cardInfo1, is_default: false, address_id: this.form.address_id };
						await this.creditCard1.createCreditCard(this.$user.details().id, payload).then((response) => {
							this.alert.toast('success', this.translate('credit_card_created'), { timer: 6000 });
							this.$emit('cardCreated', response.response.card_id);
							this.handleCardChange(1, response.response.card_id);
						}).catch(() => {
							if (SYSTEM_ALERT.includes(this.errors.status) || this.errors.status === undefined) {
								this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
							} else if (UNPROCESSABLE.includes(this.errors.status)) {
								if (typeof this.errors.errors.token !== 'undefined') {
									if (this.lacoreV2CreditCardErrors.number) {
										this.lacoreV2CreditCardErrors.number.errorMessages.push(...this.errors.errors.token);
									}
								}
								this.alert.close();
							} else {
								this.alert.close();
							}
						}).finally(() => {
							this.creditCard1.data.loading = false;
							this.$emit('update:addCreditCard1');
						});
					}

					if (this.addCreditCard2) {
						const cardInfo2 = await this.$refs.newCreditCardView2.handleResponse();
						const payload = { token: cardInfo2, is_default: false, address_id: this.form.address_id };
						await this.creditCard2.createCreditCard(this.$user.details().id, payload).then((response) => {
							this.alert.toast('success', this.translate('credit_card_created'), { timer: 6000 });
							this.$emit('cardCreated', response.response.card_id);
							this.handleCardChange(2, response.response.card_id);
						}).catch(() => {
							if (SYSTEM_ALERT.includes(this.errors.status) || this.errors.status === undefined) {
								this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
							} else if (UNPROCESSABLE.includes(this.errors.status)) {
								if (typeof this.errors.errors.token !== 'undefined') {
									if (this.lacoreV2CreditCardErrors.number) {
										this.lacoreV2CreditCardErrors.number.errorMessages.push(...this.errors.errors.token);
									}
								}
								this.alert.close();
							} else {
								this.alert.close();
							}
						}).finally(() => {
							this.creditCard2.data.loading = false;
							this.$emit('update:addCreditCard2');
						});
					}
				});
			} catch (error) {
				this.alert.close();
			}
		},
		async getAddress() {
			const payload = { ...this.form, override_validation: true, address_type: 'billing' };

			await this.newAddress.saveAddress(this.$user.details().id, this.objectToFormData(payload)).then(() => {
				this.getAddressList(true);
			}).catch(() => {
				if (SYSTEM_ALERT.includes(this.errors.status) || this.errors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
	},
};
</script>
