<template>
	<b-card-group deck>
		<b-card
			:style="{ border: borderless ? 'none' : '' }"
			:class="{ 'mx-0': borderless }"
			:no-body="noBody">
			<b-row
				v-if="includeTitle">
				<b-col>
					<h4 class="mb-1">
						{{ translate('new_credit_card_title') }}
					</h4>
				</b-col>
			</b-row>
			<hr
				v-if="includeTitle"
				class="mt-2 mb-4">
			<b-row>
				<div class="col">
					<div class="form-group mb-0">
						<form class="w-100">
							<div class="form-group">
								<label for="cc-v2-name">{{ translate('name_on_card') }} <span class="text-danger">*</span></label>
								<span
									id="cc-v2-name"
									:class="lacoreV2CreditCardHasErrors('name') ? 'is-invalid' : ''"
									class="form-field form-control" />
								<template v-if="lacoreV2CreditCardHasErrors('name')">
									<p
										v-for="error in lacoreV2CreditCardErrors.name.errorMessages"
										:key="error"
										class="custom-invalid-feedback animated fadeIn text-danger mb-0"
										v-text="error" />
								</template>
							</div>
							<div class="form-group">
								<label for="cc-v2-number">{{ translate('card_number') }} <span class="text-danger">*</span></label>
								<span
									id="cc-v2-number"
									:class="lacoreV2CreditCardHasErrors('number') ? 'is-invalid' : ''"
									class="form-field form-control" />
								<template v-if="lacoreV2CreditCardHasErrors('number')">
									<p
										v-for="error in lacoreV2CreditCardErrors.number.errorMessages"
										:key="error"
										class="custom-invalid-feedback animated fadeIn text-danger mb-0"
										v-text="error" />
								</template>
							</div>
							<div class="row">
								<div class="form-group col-12 col-md-4">
									<label for="cc-v2-expiration-month">{{ translate('expiry_month') }} <span class="text-danger">*</span></label>
									<span
										id="cc-v2-expiration-month"
										:class="lacoreV2CreditCardHasErrors('expiration_month') ? 'is-invalid' : ''"
										class="form-field form-control" />
									<template v-if="lacoreV2CreditCardHasErrors('expiration_month')">
										<p
											v-for="error in lacoreV2CreditCardErrors.expiration_month.errorMessages"
											:key="error"
											class="custom-invalid-feedback animated fadeIn text-danger mb-0"
											v-text="error" />
									</template>
								</div>
								<div class="form-group col-12 col-md-4">
									<label for="cc-v2-expiration-year">{{ translate('expiry_year') }} <span class="text-danger">*</span></label>
									<span
										id="cc-v2-expiration-year"
										:class="lacoreV2CreditCardHasErrors('expiration_year') ? 'is-invalid' : ''"
										class="form-field form-control" />
									<template v-if="lacoreV2CreditCardHasErrors('expiration_year')">
										<p
											v-for="error in lacoreV2CreditCardErrors.expiration_year.errorMessages"
											:key="error"
											class="custom-invalid-feedback animated fadeIn text-danger mb-0"
											v-text="error" />
									</template>
								</div>
								<div class="form-group col-12 col-md-4">
									<label for="cc-v2-cvc">{{ translate('cvv') }} <span class="text-danger">*</span></label>
									<span
										id="cc-v2-cvc"
										:class="lacoreV2CreditCardHasErrors('security_code') ? 'is-invalid' : ''"
										class="form-field form-control" />
									<template v-if="lacoreV2CreditCardHasErrors('security_code')">
										<p
											v-for="error in lacoreV2CreditCardErrors.security_code.errorMessages"
											:key="error"
											class="custom-invalid-feedback animated fadeIn text-danger mb-0"
											v-text="error" />
									</template>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div
					v-if="showDefaultSwitch"
					class="col-12">
					<div class="row">
						<div class="col-2 col-sm-auto pr-0">
							<switch-toggle
								id="default_card"
								v-model="form.is_default"
								name="default_card"
								variant="success"
								pill />
						</div>
						<div class="col-10 col-lg d-flex align-items-end">
							<label
								for="default_card"
								class="mb-0">{{ translate('default_toggle') }}</label>
						</div>
					</div>
				</div>
			</b-row>
			<b-row v-if="!!$slots.middle">
				<b-col>
					<slot name="middle" />
				</b-col>
			</b-row>
			<hr v-if="!showDisclaimer">
			<b-row>
				<b-col>
					<div class="h4 mb-2">
						{{ translate('billing_address') }}
					</div>
				</b-col>
			</b-row>
			<b-row
				v-if="showDisclaimer">
				<b-col>
					<b-alert show>
						{{ translate('billing_disclaimer') }}
					</b-alert>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<address-radio-button
						:address-data="addressData"
						:address-loading="addressLoading"
						:form-import="form"
						:hide-new-address="addAddress"
						:display-add-new-address="false"
						@add-address="addressFormState" />
					<transition
						name="component-fade"
						mode="in-out">
						<address-country-form
							v-if="addAddress && !addressLoading"
							:user-country="SHOW_USER_COUNTRY"
							:form-import="form.address"
							:errors-import="errors.errors"
							:prefix-errors="'address.'"
							:display-cancel-button="false"
							:display-submit-button="false"
							:display-goback-button="false" />
					</transition>
				</b-col>
			</b-row>
			<b-row
				v-if="(typeof errors.errors['address_id'] !== 'undefined')">
				<b-col>
					<b-alert
						variant="danger"
						show>
						<div
							v-for="error in errors.errors['address_id']"
							:key="error">
							<span
								v-text="error" />
							<br>
						</div>
					</b-alert>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					v-if="!addressLoading"
					:class="['xs', 'sm'].includes(windowWidth) ? 'mb-3' : ''"
					class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
					<button
						v-if="addAddress && hasAddressData"
						:class="`${['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''} ${ newAddressBtnClasses }`"
						class="btn b-4 float-left"
						@click="addressFormState">
						{{ translate('see_address_list') }}
					</button>
					<button
						v-else-if="!addAddress"
						:class="`${['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''} ${ newAddressBtnClasses }`"
						class="btn b-4 float-left"
						@click="addressFormState">
						{{ translate('use_another_address') }}
					</button>
				</b-col>
				<b-col
					v-if="showButtons">
					<button
						:disabled="(!hasAddressData && !addAddress) || newCreditLoading"
						:class="['xs', 'sm'].includes(windowWidth) ? 'w-48' : 'ml-2'"
						class="btn btn-primary b-4 float-right"
						@click="newCreditCard(form)">
						{{ translate('submit') }}
					</button>
					<button
						:class="['xs', 'sm'].includes(windowWidth) ? 'w-48 float-left' : 'float-right'"
						class="btn btn-secondary b-4"
						@click="$router.push({ name: 'AllCreditCards' })">
						{{ translate('go_back') }}
					</button>
				</b-col>
			</b-row>
		</b-card>
	</b-card-group>
</template>
<script>

import AddressRadioButton from '@/components/AddressRadioButton';
import AddressCountryForm from '@/components/AddressCountryForm';
import SwitchToggle from '@/components/Switch';
import WindowSizes from '@/mixins/WindowSizes';
import {
	Profile, Validations, Addresses, Purchase,
} from '@/translations';
import CreditCard from '@/util/CreditCard';
import AddressBook from '@/util/AddressBook';
import Country from '@/util/Country';
import { SHOW_USER_COUNTRY } from '@/settings/Country';
import { SYSTEM_ALERT, UNPROCESSABLE } from '@/settings/Errors';
import CreditCardFormV2 from '../mixins/CreditCardFormV2';
import EventBus from '@/util/eventBus';

export default {
	name: 'NewCreditCard',
	messages: [Profile, Validations, Addresses, Purchase],
	components: {
		AddressRadioButton,
		AddressCountryForm,
		SwitchToggle,
	},
	mixins: [CreditCardFormV2, WindowSizes],
	props: {
		borderless: {
			type: Boolean,
			default: false,
		},
		showButtons: {
			type: Boolean,
			default: true,
		},
		includeTitle: {
			type: Boolean,
			default: true,
		},
		showDisclaimer: {
			type: Boolean,
			default: false,
		},
		showDefaultSwitch: {
			type: Boolean,
			default: true,
		},
		noBody: {
			type: Boolean,
			default: false,
		},
		newAddressBtnVariant: {
			type: String,
			default: '',
		},
		newAddressBtnClass: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			form: {
				first_name: '',
				last_name: '',
				expiry_month: 1,
				expiry_year: this.$moment().year(),
				cvv: '',
				cc_number: '',
				card_type: '',
				address_id: '',
				is_default: false,
				address: {
					first_name: '',
					last_name: '',
					address: '',
					address2: '',
					city: '',
					postcode: '',
					country_code: '',
					region_id: '',
				},
			},
			creditCard: new CreditCard(),
			alert: new this.$Alert(),
			addressBook: new AddressBook(),
			updateAddressBook: new AddressBook(),
			getSuggestion: new AddressBook(),
			country: new Country(),
			states: new Country(),
			addAddress: false,
			SHOW_USER_COUNTRY,
			state: new Country(),
			countryIso: new Country(),
			getBillingDetails: new AddressBook(),
			paymentProcessor: process.env.VUE_APP_CC_PAYMENT_PROCESSOR,
		};
	},
	computed: {
		newAddressBtnClasses() {
			return `btn-${this.newAddressBtnVariant || 'secondary'} ${this.newAddressBtnClass}`;
		},
		errors() {
			return this.creditCard.data.errors;
		},
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		newCreditLoading() {
			return !!this.creditCard.data.loading;
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data.filter((address) => !address.attributes.is_pickup_address);
			} catch (error) {
				return [];
			}
		},
		hasAddressData() {
			const response = this.addressData.length;
			return !!response;
		},
	},
	watch: {
		hasAddressData: {
			deep: true,
			handler(newVal) {
				this.addAddress = !newVal;
			},
		},
		'form.address_id': function addressId() {
			if (Object.keys(this.errors.errors).length > 0) {
				delete this.errors.errors.address_id;
				this.errors.errors = { ...this.errors.errors };
			}
		},
	},
	mounted() {
		this.initialize();
		EventBus.$on('reloadCardAddresses', () => {
			this.loadAddresses();
		});
	},
	methods: {
		initialize() {
			// Initialize Lacore payment form
			if (this.paymentProcessor === 'LPT') {
				this.lacoreV2FormSetup();
			}
			this.loadAddresses();
		},
		async newCreditCard(inputForm) {
			let isExternalCall = false;
			let form = inputForm;
			if (form === undefined) {
				form = this.form;
				isExternalCall = true;
			}
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);
			let payload = '';
			if (this.paymentProcessor === 'GPG') {
				payload = { ...form };
				payload.address = undefined;
				payload.address_id = undefined;
				if (payload.expiry_month !== '' && payload.expiry_month.toString().length === 1) {
					payload.expiry_month = `0${payload.expiry_month}`;
				}
				if (payload.expiry_year !== '') {
					payload.expiry_year = payload.expiry_year.toString().substring(2);
				}
			} else {
				await this.lacoreV2HandleResponse();
				payload = { is_default: form.is_default, token: this.lacoreV2PaymentToken };
			}
			if (this.addAddress) {
				payload.address = form.address;
			} else {
				payload.address_id = form.address_id;
			}
			this.creditCard.createCreditCard(this.$user.details().id, payload).then((response) => {
				this.alert.toast('success', this.translate('credit_card_created'), { timer: 6000 });

				if (!isExternalCall) {
					this.$router.push({ name: 'AllCreditCards' });
				} else {
					this.$emit('cardCreated', response.response.card_id);
				}
			}).catch(() => {
				if (this.paymentProcessor === 'GPG') this.form = form;
				if (SYSTEM_ALERT.includes(this.errors.status) || this.errors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else if (UNPROCESSABLE.includes(this.errors.status)) {
					if (typeof this.errors.errors.token !== 'undefined') {
						if (this.lacoreV2CreditCardErrors.number) {
							this.lacoreV2CreditCardErrors.number.errorMessages.push(...this.errors.errors.token);
						}
					}
					this.alert.close();
				} else {
					this.alert.close();
				}
			}).finally(() => {
				this.creditCard.data.loading = false;
			});
		},
		loadAddresses() {
			this.addressBook.getAddresses(this.$user.details().id).then((response) => {
				const address = response.find((item) => !item.attributes.is_pickup_address);
				if (address) this.form.address_id = address.id;
			});
		},
		addressFormState() {
			if (Object.keys(this.errors.errors).length > 0) {
				const errorFields = Object.keys(this.errors.errors);
				errorFields.forEach((field) => {
					delete this.errors.errors[field];
					this.errors.errors = { ...this.errors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
	},
};
</script>

<style>
	.w-48 {
		width: 48%;
	}
</style>
