import AgencySite from '@/mixins/AgencySite';
import { EXPIRATION_VALUE, EXPIRATION_TIME } from '@/settings/Cookie';
import { MIN_QTY, MAX_QTY } from '@/settings/Cart';
import { DDDDMMMDO_FORMAT } from '@/settings/Dates';
import { NOT_FOUND, FORBIDDEN, BAD_REQUEST } from '@/settings/Errors';
import { TICKET_CODES } from '@/settings/Events';
import {
	GRANDFATHERED_PREFIXES_RELATED_PRODUCT_MAP,
	RELATED_PRODUCTS_BLACKLIST,
	AGENCY_PRODUCTS,
	AGENCY_RELATED_PRODUCTS_BLACKLIST,
} from '@/settings/Products';
import Cart from '@/util/Cart';
import CustomCarts from '@/util/CustomCarts';
import Packages from '@/util/GeneralInformation';
import { trackEvent } from '@/util/GoogleTagManager';
import RelatedProducts from '@/util/Products';

export default {
	mixins: [AgencySite],
	data() {
		return {
			cookieCartId: null,
			storedCartId: 0,
			useCartCookie: true,
			alert: new this.$Alert(),
			cart: new Cart(),
			createCart: new Cart(),
			removeCart: new Cart(),
			addCartProduct: new Cart(),
			usePointsUtil: new Cart(),
			removePointsUtil: new Cart(),
			splitPaymentUtil: new Cart(),
			setCartCoupon: new Cart(),
			removeCartCoupon: new Cart(),
			upgradePackagesUtil: new Packages(),
			cartRelated: new RelatedProducts(),
			qty: {},
			cartOptions: {},
			eventAttendees: {},
			customCart: new CustomCarts(),
			ordinalDateFormat: DDDDMMMDO_FORMAT,
			customCartId: '',
		};
	},
	computed: {
		possibleDiscountMessages() {
			try {
				return this.cart.data.response.data.data.attributes.possible_discounts;
			} catch (error) {
				return [];
			}
		},
		promotions() {
			try {
				return this.cart.data.response.data.data.attributes.promotions;
			} catch (error) {
				return [];
			}
		},
		showMessages() {
			return this.possibleDiscountMessages.length > 0 || this.promotions.length > 0 || this.freeProductsInfo.length > 0;
		},
		cartId() {
			if (this.useCartCookie) {
				// cookieCartId is here for vue to know when to update this computed prop
				return this.cookieCartId || this.$user.getCartId();
			}
			return this.storedCartId;
		},
		cartErrors() {
			return this.cart.data.errors;
		},
		removeCartErrors() {
			return this.removeCart.data.errors;
		},
		addCartProductErrors() {
			return this.addCartProduct.data.errors;
		},
		existingCartErrors() {
			return {
				cartErrors: this.cartErrors,
				removeCartErrors: this.removeCartErrors,
				addCartProductErrors: this.addCartProductErrors,
			};
		},
		cartPagination() {
			return this.cart.data.pagination;
		},
		cartProducts() {
			try {
				return this.cartData.attributes.products;
			} catch (error) {
				return [];
			}
		},
		isVirtualOrder() {
			try {
				return this.cartData.attributes.is_virtual;
			} catch (error) {
				return false;
			}
		},
		hideAddresses() {
			try {
				return this.cartData.attributes.hide_address;
			} catch (error) {
				return false;
			}
		},
		hidePayment() {
			try {
				return this.cartData.attributes.hide_payment;
			} catch (error) {
				return false;
			}
		},
		skipPaymentMinValidation() {
			try {
				return this.cartData.attributes.remove_payment_min_limit;
			} catch (error) {
				return false;
			}
		},
		cartRelatedProducts() {
			try {
				const cartProducts = this.cartProducts.map((product) => {
					if (product.product.parent === undefined) {
						return product.sku;
					}
					return product.product.parent.sku;
				}).map((cartSku) => {
					// Replace grandfathered SKUs:
					//   Since grandfathered products don't have parents in the cart response,
					//   we manually map their SKUs to their non-grandfathered parent products
					const applicableEntry = Object.entries(GRANDFATHERED_PREFIXES_RELATED_PRODUCT_MAP)
						.filter(([prefix]) => cartSku.startsWith(prefix));

					if (applicableEntry.length > 0) {
						const replacement = applicableEntry[0][1];
						return replacement;
					}

					return cartSku;
				});

				let relatedProducts = [];
				if (this.isAgencySite) {
					relatedProducts = AGENCY_PRODUCTS.data.filter((product) => ['BR', 'ZL', 'BY', 'UT', 'PL'].includes(product.attributes.sku));
				} else {
					relatedProducts = this.cartRelated.data.response.data.data;
				}

				const cartRelated = relatedProducts
					.filter((product) => !cartProducts.includes(product.attributes.sku)
						&& !RELATED_PRODUCTS_BLACKLIST.includes(product.attributes.sku)
						&& ((this.$route.name.search('Agency') >= 0
								&& !AGENCY_RELATED_PRODUCTS_BLACKLIST.includes(product.attributes.sku))
							|| this.$route.name.search('Agency') < 0));

				return cartRelated;
			} catch (error) {
				return [];
			}
		},
		cartTotals() {
			try {
				return this.cartData.attributes.totals;
			} catch (error) {
				return {};
			}
		},
		cartPoints() {
			try {
				return this.cartData.attributes.points_summary;
			} catch (error) {
				return {};
			}
		},
		creditDiscount() {
			try {
				return this.cartData.attributes.credit_promotion;
			} catch (error) {
				return {};
			}
		},
		cartSmallTextTotals() {
			try {
				return this.cartData.attributes.small_subtotals;
			} catch (error) {
				return [];
			}
		},
		cartNumericTotals() {
			try {
				return this.cartData.attributes.numeric_totals;
			} catch (error) {
				return {};
			}
		},
		cartNumericTotal() {
			try {
				return this.cartData.attributes.numeric_total;
			} catch (error) {
				return 0;
			}
		},
		canSplitPayment() {
			try {
				return this.cartData.attributes.can_split_payment;
			} catch (error) {
				return false;
			}
		},
		numericTotalPurchased() {
			try {
				return Math.ceil((this.cartNumericTotals.subtotal || 0) + (this.cartNumericTotals.discount || 0));
			} catch (error) {
				return 0;
			}
		},
		totalVolume() {
			try {
				return this.cartData.attributes.total_volume.toString();
			} catch (error) {
				return '';
			}
		},
		upgradeVolume() {
			try {
				return this.cartData.attributes.upgrade_volume;
			} catch (error) {
				return null;
			}
		},
		cartTotal() {
			try {
				return this.cartData.attributes.total;
			} catch (error) {
				return '';
			}
		},
		shippingMethod() {
			try {
				return this.cartData.attributes.shipping_method;
			} catch (error) {
				return '';
			}
		},
		showShippingMethodAlert() {
			try {
				const validCountry = this.cartData.attributes.shipping_options.show_shipping_alert_to_country;
				const validMethod = this.cartData.attributes.shipping_options.is_alert_shipping_method;
				return validCountry && validMethod;
			} catch (error) {
				return false;
			}
		},
		applyFreeBottle() {
			try {
				return this.country === 'US' && this.cartData.attributes.apply_free_bottle;
			} catch (error) {
				return false;
			}
		},
		freeBottleMessage() {
			try {
				return this.cartData.attributes.free_bottle_message;
			} catch (error) {
				return '';
			}
		},
		cartCoupon() {
			try {
				const coupon = this.cartData.attributes.coupon_data;
				return !Array.isArray(coupon) ? coupon : {};
			} catch (error) {
				return {};
			}
		},
		termsAndPolicies() {
			try {
				return this.cartMeta.terms;
			} catch (error) {
				return {};
			}
		},
		couponErrors() {
			try {
				return JSON.parse(JSON.stringify(this.setCartCoupon.errors.errors));
			} catch (error) {
				return {};
			}
		},
		splitPaymentLoading() {
			return this.splitPaymentUtil.data.loading;
		},
		couponLoading() {
			return this.setCartCoupon.data.loading;
		},
		removeCouponLoading() {
			return this.removeCartCoupon.data.loading;
		},
		cartData() {
			try {
				return this.cart.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		cartMeta() {
			try {
				return this.cart.data.response.data.meta;
			} catch (error) {
				return [];
			}
		},
		cartHasData() {
			const response = Object.keys(this.cartData).length;
			return !!response;
		},
		creditsUsed() {
			try {
				const { points_used: creditsUsed } = this.cart.data.response.data.data.attributes.points_summary;
				return Number(creditsUsed);
			} catch (error) {
				return 0;
			}
		},
		hasCredits() {
			try {
				return this.cart.data.response.data.data.attributes.points_summary.total_points > 0;
			} catch (error) {
				return false;
			}
		},
		exchange() {
			try {
				const { exchange } = this.cart.data.response.data.data.attributes;
				return exchange;
			} catch (error) {
				return [];
			}
		},
		productsHasData() {
			if (this.cartHasData) {
				const response = this.cartData.attributes.products.length;
				return !!response;
			}
			return false;
		},
		totalsHasData() {
			if (this.cartHasData) {
				const response = Object.keys(this.cartData.attributes.totals).length;
				return !!response;
			}
			return false;
		},
		cartRemovalResponse() {
			try {
				const { response } = this.removeCart.data.response.data;
				return response;
			} catch (error) {
				return [];
			}
		},
		tickets() {
			try {
				const tickets = this.cartProducts.filter((product) => TICKET_CODES.includes(product.code_name));
				const result = [];
				tickets.forEach((product) => {
					// eslint-disable-next-line camelcase
					const companionTypes = product.ticket_configurations?.attendees_options ?? [];
					// eslint-disable-next-line camelcase
					const bedTypes = product.ticket_configurations?.room_types_available ?? [];
					const ticketData = {
						code_name: product.code_name,
						sku: product.sku,
						qty: product.parent_qty ?? product.qty,
						tickets_count: product.tickets_count,
						companion_types: companionTypes.map((type) => ({ value: type, text: this.translate(type) })),
						bed_types: bedTypes.map((type) => ({ value: type, text: this.translate(type) })),
						// eslint-disable-next-line camelcase
						check_in_dates: (product.ticket_configurations?.check_in_dates ?? []).map((date) => ({ value: date, text: this.$moment(date).format(this.ordinalDateFormat) })),
						// eslint-disable-next-line camelcase
						reservation_nights: product.ticket_configurations?.reservation_nights ?? 0,
						// eslint-disable-next-line camelcase
						total_tickets: product.qty ?? 0,
						// eslint-disable-next-line camelcase
						room_types_visibility: product.ticket_configurations?.room_types_visibility ?? {},
					};
					result.push(ticketData);
				});

				return result;
			} catch (error) {
				return [];
			}
		},
		cartHasTickets() {
			return !!this.tickets.length;
		},
		upgradePackages() {
			try {
				return this.upgradePackagesUtil.data.response.data.data.map((item, index) => ({ id: item.id, index, ...item.attributes }));
			} catch (error) {
				return [];
			}
		},
		loadingUpgradePackages() {
			try {
				return this.upgradePackagesUtil.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasUpgradePackages() {
			return !!this.upgradePackages.length;
		},
		systemLoading() {
			const ops = [
				this.cart,
				this.addCartProduct,
				this.removeCart,
				this.createCart,
				this.usePointsUtil,
				this.removePointsUtil,
				this.upgradePackagesUtil,
			];

			return ops.reduce((loading, op) => (!!op.data.loading || loading), false);
		},
		splitPaymentInfo() {
			try {
				return this.cartData.attributes.split_payments;
			} catch (error) {
				return {};
			}
		},
		freeProductsInfo() {
			try {
				const info = this.cartData.attributes.free_products_info;
				return Object.keys(info).map((key) => info[key]);
			} catch (error) {
				return [];
			}
		},
		discountDetail() {
			try {
				return this.cartData.attributes.discount_detail;
			} catch (error) {
				return [];
			}
		},
		allowGiftCart() {
			try {
				return this.cartData.attributes.available_for_gift;
			} catch (error) {
				return false;
			}
		},
		freeShippingInfo() {
			try {
				return this.cartData.attributes.free_shipping_info;
			} catch (error) {
				return null;
			}
		},
		pickupOffices() {
			try {
				return this.cartData.attributes.pickup_offices;
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		existingCartErrors: {
			deep: true,
			handler(errors) {
				Object.keys(errors).forEach((key) => {
					const customCart = localStorage.getItem('custom_cart_uuid') || false;
					const sameAsCustom = this.customCartId === this.cartId;
					if ([...NOT_FOUND, ...FORBIDDEN, ...BAD_REQUEST].includes(errors[key].status) && (!customCart || sameAsCustom)) {
						if (sameAsCustom) {
							// If current cart is a custom cart, and an error exist, remove custom cart from local storage
							localStorage.removeItem('custom_cart_uuid');
						}
						this.createNewCart().then(() => {
							const redirectName = this.getRedirectName('Store');
							if (this.$route.name !== redirectName) {
								this.$router.replace({ name: redirectName });
							}
						});
					}
				});
			},
		},
		cartId() {
			if ((this.showPublicStore) || this.$route.name === 'RegisterConfirmation') {
				this.getCartRelated();
				this.getCartTotals();
			}
		},
	},
	methods: {
		async updateQuantity(newQuantity, product, bypassMinQty = false, pickupAtOffice = null) {
			trackEvent(this.$gtm, 'productQtyUpdated'); // TODO: track product quantity and sku
			try {
				let quantity = bypassMinQty ? 0 : MIN_QTY;

				if (Number.isNaN(newQuantity) || newQuantity > MIN_QTY) quantity = newQuantity;
				if (Number.isNaN(newQuantity) || newQuantity > MAX_QTY) {
					quantity = MAX_QTY;
					this.alert.toast('info', this.translate('max_qty', { max: MAX_QTY }));
				}
				const products = { [product.sku]: quantity };

				await this.addCartProduct.addCartProducts(this.cartId, {
					products, replace_qty: true, sponsor_id: this.$replicated.replicatedSponsor(), pickup_at_office: pickupAtOffice,
				});
				this.qty[product.item_id] = quantity;
				this.getCartTotals();
			} catch (e) {
				if (typeof this.addCartProduct.errors.errors.products !== 'undefined') {
					let response = '';
					this.addCartProduct.errors.errors.products.forEach((item) => { response += `${item} \n`; });
					this.alert.toast('error', response, { timer: 4000 });
				} else {
					this.alert.toast('error', this.translate('default_error_message'));
				}
			}
		},
		async removeProduct(product, pickupAtOffice = null) {
			await this.updateQuantity(0, product, true, pickupAtOffice);
		},
		// Do not call this function directly. Instead, call getCartTotals()
		getTotals() {
			if (this.cartId === null) {
				if (this.country !== null) this.createNewCart();
			} else {
				if (this.isAgencySite) {
					const checkDiscounts = this.$replicated.replicatedCheckPromo() ?? false;
					const replicatedSponsor = this.$replicated.replicatedSponsor();
					const replicatedDiscountCode = this.$replicated.replicatedDiscountCode();
					this.cartOptions = {
						...this.cartOptions,
						sponsor: replicatedSponsor,
						check_discount: checkDiscounts ? 1 : 0,
						discount_code: replicatedDiscountCode,
					};
				}

				const section = this.flowName ?? (this.$user.auth() ? 'purchase' : 'checkout');
				this.cart.getCart(this.cartId, this.cartOptions, section).then((response) => {
					response.attributes.products.forEach((item) => {
						this.$set(this.qty, item.item_id, item.qty);
					});
				}).catch((getCartError) => {
					if (![...NOT_FOUND, ...FORBIDDEN, ...BAD_REQUEST].includes(getCartError.status)) {
						this.alert.toast('error', this.translate('default_error_message'));
					}
				});
			}
		},
		getCartTotals() {
			const replicatedPage = this.$replicated.replicatedPage();
			const customCartExists = localStorage.getItem('custom_cart_uuid') || false;
			const normalCustomCart = (replicatedPage === 'checkout') && customCartExists;
			if (normalCustomCart && this.$route.name !== 'RegisterConfirmation') {
				// When the replicated site is 'checkout' or 'store' and has a custom cart, it means the user is using a custom cart
				// So, we'll get cart totals only if the cart id corresponds to a custom cart
				this.customCart.getCustomCart().then((response) => {
					this.customCartId = response.attributes.cart_id;
					this.getTotals();
				});
				return;
			}

			this.getTotals();
		},
		getCartRelated() {
			if (!this.isAgencySite) {
				this.cartRelated.getProducts(this.country, 'main');
			}
		},
		createNewCart() {
			return this.createCart.createCart().then(() => {
				this.setCartCookie(this.createCart.data.response.data.response.cart_id, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME.minutes}` });
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		renewCart() {
			return this.removeCart.removeCart(this.cartId).then(() => {
				this.setCartCookie(this.cartRemovalResponse.cartId, { expired: `${EXPIRATION_VALUE}${EXPIRATION_TIME.minutes}` });
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		setCartCookie(id, data, getCartTotals = true) {
			if (typeof id !== 'undefined' && (this.cartId !== id || this.cartId === null)) {
				this.$cookie.delete('cart_id');
				this.$cookie.set('cart_id', id, data);
				this.cookieCartId = id;
			}
			if (getCartTotals) {
				return this.getCartTotals();
			}
			return null;
		},
		saveTickets(eventTicket, attendees) {
			this.eventAttendees[eventTicket] = attendees;
			this.eventAttendees = { ...this.eventAttendees };
		},
		async usePoints() {
			try {
				await this.usePointsUtil.usePoints(this.cartId);
			} catch (error) {
				this.alert.toast('error', this.translate('default_error_message'));
			} finally {
				this.getCartTotals();
			}
		},
		async removePoints() {
			try {
				await this.removePointsUtil.removePoints(this.cartId);
			} catch (error) {
				this.alert.toast('error', this.translate('default_error_message'));
			} finally {
				this.getCartTotals();
			}
		},
		applyCoupon(couponCode, options = {}) {
			const params = {
				coupon_code: couponCode,
				...options,
			};

			this.setCartCoupon.setCartCoupon(this.$user.getCartId(), params).then(() => {
				this.setCartCoupon.clear();
				this.getCartTotals();
			}).catch(() => {});
		},
		removeCoupon() {
			this.removeCartCoupon.removeCartCoupon(this.$user.getCartId()).then(() => this.getCartTotals());
		},
		handleCouponChange() {
			this.setCartCoupon.errors.clear(true);
		},
		getUpgradePackagesInfo() {
			return this.upgradePackagesUtil.getAvailablePackages(this.$user.details().id);
		},
		splitPayment(split) {
			this.splitPaymentUtil.splitPayment(this.cartId, { split_payment: split }).then(() => {
				this.getCartTotals();
				this.splitPaymentUtil.data.loading = false;
			});
		},
	},
};
